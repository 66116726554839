<template>
  <mobile-screen :header="true" screen-class="pass-screen icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="pass-header-menu icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_pass-help-online',
        }"
      >
        <template v-slot:left>
          <home-button v-if="!routeParam" />
          <button
            v-else
            @click="
              $router.push({
                name: backLinkName ? backLinkName : 'r_pass',
              })
            "
          >
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          {{ displayLabelName("pass", "pass", "space-pass") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <pass-list
      v-if="!routeParam"
      :passes="passes && passes.data"
      :mode="mode"
      :display-pass="displayPass"
    />
    <pass-detail v-else :pass="pass && pass.data" />
    <template v-slot:footer>
      <plans-footer-menu> <qr-code-scanner-button /></plans-footer-menu>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import PlansFooterMenu from "@/components/plans/PlansFooterMenu";
import { mapActions, mapState } from "vuex";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import { getUserId } from "@/services/http-service";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "Pass",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    QrCodeScannerButton,
    PlansFooterMenu,
    PassList: defineAsyncComponent(() => import("@/components/pass/PassList")),
    PassDetail: defineAsyncComponent(() =>
      import("@/components/pass/PassDetail")
    ),
  },
  data() {
    return {
      mode: "view",
      channel: null,
      helpSlug: "plan-resources-space-pass",
    };
  },
  computed: {
    ...mapState("pass", ["passes", "pass", "backLinkName"]),
    routeParam() {
      return this.$route.params && this.$route.params.pass_id;
    },
  },
  created() {
    if (this.routeParam) {
      this.getPass({
        id: this.routeParam,
        params: {
          includes: ["declaration_status", "full_path"],
        },
      });
    } else {
      this.getPasses({
        params: { includes: ["declaration_status", "full_path"] },
        noLoaders: false,
      });
    }
  },
  mounted() {
    this.channel = subscribe(
      "declaration",
      [getUserId()],
      wsEvents.declaration.changed,
      this.wsCallback
    );
  },
  watch: {
    routeParam() {
      if (this.routeParam) {
        this.getPass({
          id: this.routeParam,
          params: {
            includes: ["declaration_status", "full_path"],
          },
        });
      } else {
        this.getPasses({
          params: { includes: ["declaration_status", "full_path"] },
          noLoaders: false,
        });
      }
    },
  },
  methods: {
    ...mapActions("pass", ["getPasses", "getPass"]),
    changeStep() {
      if (this.step === 3) {
        this.step = 1;
      } else {
        this.step++;
      }
    },
    displayPass(id) {
      this.$router.push({
        name: "r_pass",
        params: {
          pass_id: id,
        },
      });
    },
    wsCallback() {
      this.getPasses({
        params: { includes: ["declaration_status", "full_path"] },
        noLoaders: true,
      }).then(() => {
        if (this.routeParam) {
          const updatedPass = this.passes.data.find(
            (el) => el.id === Number(this.routeParam)
          );
          if (updatedPass) {
            this.$store.commit(
              "pass/setPassData",
              { data: updatedPass },
              {
                root: true,
              }
            );
          } else {
            this.$store.commit("pass/setPassData", null, {
              root: true,
            });
            this.$router.push({
              name:
                this.$route.params && this.$route.params.backLinkName
                  ? this.$route.params.backLinkName
                  : "r_pass",
            });
          }
        }
      });
    },
  },
  beforeUnmount() {
    unsubscribe(this.channel);
    this.$store.commit("pass/setBackLinkName", null, { root: true });
  },
};
</script>
